
      import { useDark } from '@vueuse/core'
      const isDark = useDark()

      try {
        if (isDark.value || window.matchMedia('(prefers-color-scheme: dark)').matches) {
          document.documentElement.classList.add('dark')
        } else {
          document.documentElement.classList.remove('dark')
        }

        window.addEventListener('helper-theme-changed', (event) => {
          if (event.detail.theme == 'dark') {
            document.documentElement.classList.add('dark')
          }
          else {
            document.documentElement.classList.remove('dark')
          }
        })
      } catch (err) {
        console.error(err)
      }
    