<template>
   <div class="fixed inset-0">
      <BuildInformation />
      <slot></slot>
   </div>
</template>

<script>
import BuildInformation from '@/components/Debug/BuildInformation.vue'
export default {
  name: 'Empty',
  components: { BuildInformation }
}
</script>
