<template>
  <div class="flex flex-col flex-1 gap-x-2">
    <Columns gap="small" class="my-6">
      <!-- Code Column -->
      <Column width="2/3" class="relative block overflow-hidden h-full text-[13px] leading-[18px] rounded-lg border border-gray-100/50 bg-white dark:bg-gradient-to-r dark:from-slate-900 dark:to-slate-700 p-4 pb-7">
        <Box v-slot="{ elementWidth }" marginTop="xsmall">
          <Box full justifyContent="between" marginBottom="gutter" gapY="gutter" noWrap :shouldCollapse="elementWidth < 400">
            <Box gap="small" :shouldCollapse="elementWidth < 330">
              <!-- Button Prop Sidebar -->
              <Box>
                <Sidebar v-model="openButtonPropSidebar" heading="Button Props">
                  <Stack gap="xsmall">
                    <Text marginY="small" attention>
                      Below are all available props to the Button Component.
                    </Text>

                    <Stack gap="small">
                      <!-- Dropdown for Color Props -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Button Colors</Label>
                        <Dropdown v-model="state.color">
                          <Option v-for="color in colors" :key="color" :value="color">
                            {{ color }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Dropdown for Color Sizes -->
                      <Stack class="sm:w-[14rem]">
                        <Label>Sizes</Label>
                        <Dropdown v-model="state.size">
                          <Option v-for="size in sizes" :key="size" :value="size">
                            {{ size }}
                          </Option>
                        </Dropdown>
                      </Stack>

                      <!-- Checkboxes for Other Props -->
                      <Stack marginTop="small" gap="small">
                        <Checkbox v-model="state.disabled" label="Disabled" />
                        <Checkbox v-model="state.loading" label="Loading" />
                        <Checkbox v-model="state.full" label="Full" />
                        <Checkbox v-model="state.outline" label="Outline" />
                      </Stack>
                    </Stack>
                  </Stack>
                  <!-- Open Sidebar -->
                </Sidebar>
                <Box @click="openButtonPropSidebar = true" justifyContent="center" class="text-sm text-slate-100 font-attention rounded px-3 py-1 cursor-pointer bg-slate-500/75 border border-slate-400/50" :full="elementWidth < 330">
                  Button Props
                </Box>
              </Box>
              <!-- Reset Playground -->
              <p @click="resetPlayground" class="bg-red-700 px-3 py-1 text-sm text-center text-slate-100 font-attention rounded cursor-pointer hover:bg-red-800">Reset</p>
            </Box>
            <!-- Import Statement Copy Button -->
            <Button
                @click="copy(importStatement)"
                color="gray"
                size="sm"
                outline
              >
              {{ copied ? 'Copied Import' : 'Copy Import' }}
            </Button>
          </Box>
        </Box>

        <!-- View HTML -->
          <div>
            <!-- Button Opening Tag -->
            <pre>&lt;<span class="text-lime-600 dark:text-lime-600 font-semibold">Button</span><span v-if="shouldShowNoPropClosingCarrot">&gt;</span></pre>
            <!-- Optional props -->
            <pre v-if="state.color !== 'base'"><span class="text-orange-600 dark:text-orange-400 ml-2">color</span><span>="{{ state.color }}"</span></pre>
            <pre v-if="state.size !== 'base'"><span class="text-orange-600 dark:text-orange-400 ml-2">size</span><span>="{{ state.size }}"</span></pre>
            <pre v-if="state.disabled"><span class="text-orange-600 dark:text-orange-400 ml-2">disabled</span></pre>
            <pre v-if="state.loading"><span class="text-orange-600 dark:text-orange-400 ml-2">:loading</span><span>="loading"</span></pre>
            <pre v-if="state.full"><span class="text-orange-600 dark:text-orange-400 ml-2">full</span></pre>
            <pre v-if="state.outline"><span class="text-orange-600 dark:text-orange-400 ml-2">outline</span></pre>
            <pre v-if="!shouldShowNoPropClosingCarrot">&gt;</pre>
            <pre><span> Button</span></pre>
            <!-- Button Closing Tag -->
            <pre class="mt-1">&lt;/<span class="text-lime-600 dark:text-lime-600 font-semibold">Button</span>&gt;</pre>
          </div>

        <!-- Bottom gradient bar -->
        <span class="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"/>
      </Column>

      <!-- Visual Block -->
      <Column class="h-72 p-4 bg-white dark:bg-charcoal-900 rounded-lg border border-gray-100/50">
        <Stack class="my-auto text-center">
          <Button
            :type="state.type"
            :color="state.color"
            :size="state.size"
            :disabled="state.disabled"
            :loading="state.loading"
            :full="state.full"
            :outline="state.outline"
          >
            Button
          </Button>
        </Stack>
      </Column>
    </Columns>
  </div>
</template>

<script setup>
import { Box, Columns, Column, Stack } from '@/components/Core/Layout'
import { ref, computed } from 'vue'
import Text from '@/components/Core/Text.vue'
import Sidebar from '@/components/Core/Sidebar.vue'
import Button from '@/components/Core/Button.vue'
import Label from '@/components/Core/Form/Label.vue'
import { Dropdown, Option } from '@/components/Core/Form/Dropdown'
import Checkbox from '@/components/Core/Form/Checkbox.vue'
import { useClipboard } from '@vueuse/core'

const importStatement = ref('import Button from \'@/components/Core/Button.vue\'')
const { copy, copied } = useClipboard({ importStatement })


const state = ref({
  // Button Specific Variables/Props
  // type: 'button',
  color: 'base',
  size: 'base',
  disabled: false,
  loading: false,
  full: false,
  outline: false,
})

const initialState = ref(JSON.parse(JSON.stringify(state.value)))

const openButtonPropSidebar = ref(false)

// Button color options selectable in the props sidebar
const colors = ['transparent', 'red', 'blue', 'base', 'green', 'amber', 'gray', 'dark-gray']

// Button size options selectable in the props sidebar
const sizes = ['xs', 'sm', 'base', 'xl']

const shouldShowNoPropClosingCarrot = computed(() => {
  return state.value.color === 'base' && state.value.size === 'base'
  && !state.value.disabled && !state.value.loading && !state.value.full && !state.value.outline
})

const resetPlayground = () => {
  state.value = JSON.parse(JSON.stringify(initialState.value))
}

</script>