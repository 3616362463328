<template>
  <div ref="el" :class="[
    'flex flex-col min-w-0',
    full ? 'w-full' : null,
    // Flex Justify style
    justifyContent ? `justify-${justifyContent}` : null,
    // Flex Align style
    alignItems ? `items-${alignItems}` : null,
     // Full padding
     {
      'p-1': padding === 'xxsmall',
      'p-2': padding === 'xsmall',
      'p-3': padding === 'small',
      'p-4': padding === 'medium',
      'p-6': padding === 'large',
      'p-8': padding === 'xlarge',
      'p-10': padding === 'xxlarge',
      'p-5': padding === 'gutter'
    },
    // Padding Left + Padding Right
    {
      'px-1': paddingX === 'xxsmall',
      'px-2': paddingX === 'xsmall',
      'px-3': paddingX === 'small',
      'px-4': paddingX === 'medium',
      'px-6': paddingX === 'large',
      'px-8': paddingX === 'xlarge',
      'px-10': paddingX === 'xxlarge',
      'px-5': paddingX === 'gutter'
    },
    // Padding Top + Padding Bottom
    {
      'py-1': paddingY === 'xxsmall',
      'py-2': paddingY === 'xsmall',
      'py-3': paddingY === 'small',
      'py-4': paddingY === 'medium',
      'py-6': paddingY === 'large',
      'py-8': paddingY === 'xlarge',
      'py-10': paddingY === 'xxlarge',
      'py-5': paddingY === 'gutter'
    },
    // Left Padding
    {
      'pl-1': paddingLeft === 'xxsmall',
      'pl-2': paddingLeft === 'xsmall',
      'pl-3': paddingLeft === 'small',
      'pl-4': paddingLeft === 'medium',
      'pl-6': paddingLeft === 'large',
      'pl-8': paddingLeft === 'xlarge',
      'pl-10': paddingLeft === 'xxlarge',
      'pl-5': paddingLeft === 'gutter'
    },
    // Right Padding
    {
      'pr-1': paddingRight === 'xxsmall',
      'pr-2': paddingRight === 'xsmall',
      'pr-3': paddingRight === 'small',
      'pr-4': paddingRight === 'medium',
      'pr-6': paddingRight === 'large',
      'pr-8': paddingRight === 'xlarge',
      'pr-10': paddingRight === 'xxlarge',
      'pr-5': paddingRight === 'gutter'
    },
    // Top Padding
    {
      'pt-1': paddingTop === 'xxsmall',
      'pt-2': paddingTop === 'xsmall',
      'pt-3': paddingTop === 'small',
      'pt-4': paddingTop === 'medium',
      'pt-6': paddingTop === 'large',
      'pt-8': paddingTop === 'xlarge',
      'pt-10': paddingTop === 'xxlarge',
      'pt-5': paddingTop === 'gutter'
    },
    // Bottom Padding
    {
      'pb-1': paddingBottom === 'xxsmall',
      'pb-2': paddingBottom === 'xsmall',
      'pb-3': paddingBottom === 'small',
      'pb-4': paddingBottom === 'medium',
      'pb-6': paddingBottom === 'large',
      'pb-8': paddingBottom === 'xlarge',
      'pb-10': paddingBottom === 'xxlarge',
      'pb-5': paddingBottom === 'gutter'
    },
    // Full Margin
    {
      'm-1': margin === 'xxsmall',
      'm-2': margin === 'xsmall',
      'm-3': margin === 'small',
      'm-4': margin === 'medium',
      'm-6': margin === 'large',
      'm-8': margin === 'xlarge',
      'm-10': margin === 'xxlarge',
      'm-5': margin === 'gutter'
    },
    // Margin Left + Margin Right
    {
      'mx-1': marginX === 'xxsmall',
      'mx-2': marginX === 'xsmall',
      'mx-3': marginX === 'small',
      'mx-4': marginX === 'medium',
      'mx-6': marginX === 'large',
      'mx-8': marginX === 'xlarge',
      'mx-10': marginX === 'xxlarge',
      'mx-5': marginX === 'gutter'
    },
    // Margin Top + Margin Bottom
    {
      'my-1': marginY === 'xxsmall',
      'my-2': marginY === 'xsmall',
      'my-3': marginY === 'small',
      'my-4': marginY === 'medium',
      'my-6': marginY === 'large',
      'my-8': marginY === 'xlarge',
      'my-10': marginY === 'xxlarge',
      'my-5': marginY === 'gutter'
    },
    // Left Margin
    {
      'ml-1': marginLeft === 'xxsmall',
      'ml-2': marginLeft === 'xsmall',
      'ml-3': marginLeft === 'small',
      'ml-4': marginLeft === 'medium',
      'ml-6': marginLeft === 'large',
      'ml-8': marginLeft === 'xlarge',
      'ml-10': marginLeft === 'xxlarge',
      'ml-5': marginLeft === 'gutter'
    },
    // Right Margin
    {
      'mr-1': marginRight === 'xxsmall',
      'mr-2': marginRight === 'xsmall',
      'mr-3': marginRight === 'small',
      'mr-4': marginRight === 'medium',
      'mr-6': marginRight === 'large',
      'mr-8': marginRight === 'xlarge',
      'mr-10': marginRight === 'xxlarge',
      'mr-5': marginRight === 'gutter'
    },
    // Top Margin
    {
      'mt-1': marginTop === 'xxsmall',
      'mt-2': marginTop === 'xsmall',
      'mt-3': marginTop === 'small',
      'mt-4': marginTop === 'medium',
      'mt-6': marginTop === 'large',
      'mt-8': marginTop === 'xlarge',
      'mt-10': marginTop === 'xxlarge',
      'mt-5': marginTop === 'gutter'
    },
    // Bottom Margin
    {
      'mb-1': marginBottom === 'xxsmall',
      'mb-2': marginBottom === 'xsmall',
      'mb-3': marginBottom === 'small',
      'mb-4': marginBottom === 'medium',
      'mb-6': marginBottom === 'large',
      'mb-8': marginBottom === 'xlarge',
      'mb-10': marginBottom === 'xxlarge',
      'mb-5': marginBottom === 'gutter'
    },
    // Gap between nested items
    {
      'gap-y-1': gap === 'xxsmall',
      'gap-y-2': gap === 'xsmall',
      'gap-y-3': gap === 'small',
      'gap-y-4': gap === 'medium',
      'gap-y-6': gap === 'large',
      'gap-y-8': gap === 'xlarge',
      'gap-y-10': gap === 'xxlarge',
      'gap-y-5': gap === 'gutter',
    }
  ]">

      <slot
        :breakpoint="breakpoint"
        :elementWidth="elementWidth"
        :browserWidth="browserWidth"
        :isBelowBreakpoint="isBelowBreakpoint"
        :isAboveBreakpoint="isAboveBreakpoint"
      />

  </div>
</template>

<script setup>
import { ref } from 'vue'
import useLayoutWidths from '@/composables/useLayoutWidths.js'

defineProps({
  alignItems: {
    type: String,
    validator: (val) => ['start', 'end', 'center'].includes(val),
  },
  full: {
    type: Boolean,
  },
  gap: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  justifyContent: {
    type: String,
    validator: (val) => ['center', 'start', 'end', 'between', 'around', 'normal'].includes(val),
  },
  padding: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingX: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingY: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingLeft: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingRight: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingTop: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  paddingBottom: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  margin: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginX: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginY: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginLeft: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginRight: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginTop: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  marginBottom: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  }
})

const el = ref(null)
const { breakpoint, isAboveBreakpoint, isBelowBreakpoint, elementWidth, browserWidth } = useLayoutWidths(el)
</script>
