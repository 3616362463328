<template>
  <div class="flex justify-center mb-3">
    <Loader color="#aaa" :size="32" />
  </div>
</template>

<script>
import Loader from '@/components/Core/Loader.vue'
export default {
  name: 'BaseLoadingIcon',
  components: { Loader }
}
</script>
