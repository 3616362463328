import { ref } from 'vue'

const config = ref({
  isVisible: false,
  title: 'You have unsaved changes that will be lost.',
  message: 'Are you sure?',
  confirmLabel: 'Continue',
  cancelLabel: 'Go Back',
  confirm: () => ({}),
  cancel: () => ({}),
})

export function useConfirmDialog() {
  const confirm = async (message = 'Are you sure?', options) => {
    const promise = new Promise((resolve, reject) => {
      Object.assign(config.value, {
        ...options,
        isVisible: true,
        message,
        confirm: resolve,
        cancel: reject
      })
    })

    try {
      await promise
      return config.value.confirmLabel
    } catch (error) {
      return config.value.cancelLabel
    } finally {
      config.value.isVisible = false
    }
  }

  return {
    confirm,
    config
  }
}
