export default {

  // Grab region value from drop down and set the metrics data
  CHANGEREGION: (state, payload) => {
    let selectedRegion = state.selected.metric.regions.filter(function (ele) {
      let region = ele.regionName == payload
      return region
    })
    state.selected.regions = selectedRegion
  },

  // Show the notes warning modal
  TOGGLENOTESWARNING: (state, data) => {
    if (data.value === 'reset') {
      // Reset other warning values
      state.pocErrorMessage.show = false
      state.pocErrorMessage.type = ''
      state.pocErrorMessage.index = -1
    }
    else {
      state.pocErrorMessage.show = true
      state.pocErrorMessage.type = data.value
      state.pocErrorMessage.index = data.index
    }
  },

  // Update the SE Response Status for a given
  UPDATESERESPONSE: (state, data) => {
    // Grab the POC that has been updated
    let thisPOC = state.auditGet.ownerArray[data.auditStage].filter(p => p.blockSerial === data.blockSerial)[0]
    // Check to see that the property of the ownerArray object exists
    if (state.auditGet.ownerArray['needsApproval'] === undefined) state.auditGet.ownerArray['needsApproval'] = []
    // Remove it from the original array
    state.auditGet.ownerArray[data.auditStage] = state.auditGet.ownerArray[data.auditStage].filter(p => p.blockSerial != thisPOC.blockSerial)
    // Add it to the new array
    state.auditGet.ownerArray['needsApproval'].push(thisPOC)
  },

  // Update a POC's audit stage based on manager response
  UPDATEMANAGERRESPONSE: (state, data) => {
    // Grab the index of the person who owns the POC
    let thisPerson = state.auditGet.managerArray.findIndex(p => p.name === data.owner)
    // Grab the POC that was updated
    let thisPOC = state.auditGet.managerArray[thisPerson].assets[data.auditStage].filter(p => p.blockSerial === data.blockSerial)[0]
    // Remove it from the original array
    state.auditGet.managerArray[thisPerson].assets[data.auditStage] = state.auditGet.managerArray[thisPerson].assets[data.auditStage].filter(p => p.blockSerial != data.blockSerial)
    // Add it to a new array based on the response
    if (data.managerResponse === 'Verify') {
      if (state.auditGet.managerArray[thisPerson].assets['completedAudit'] === undefined) {
        state.auditGet.managerArray[thisPerson].assets['completedAudit'] = []
      }
      state.auditGet.managerArray[thisPerson].assets['completedAudit'].push(thisPOC)
    }
    else {
      if (state.auditGet.managerArray[thisPerson].assets['needsReview'] === undefined) {
        state.auditGet.managerArray[thisPerson].assets['needsReview'] = []
      }
      state.auditGet.managerArray[thisPerson].assets['needsReview'].push(thisPOC)
    }
  },

  TOGGLESHOWMETRICPOCS: (state, payload) => {
    state.showMetricPOCs = payload
  },

  // Update the new notes value in the state for a POC
  POCNOTESUPDATE: (state, data) => {
    state.newAuditPOCNotes = data
  },

  // Update the current notes while editing
  POCEDITNOTESUPDATE: (state, data) => {
    state.editedPOCNotes = data
  },

  // Reset the POC notes value in the state after submission
  POCRESETNEWNOTES: (state) => {
    state.newAuditPOCNotes = ''
  },

  // Update the notes in the state for the current POC
  POCREFRESHNOTES: (state, data) => {
    state.auditPOCNotes = data
  },

  // Close the currently opened note textarea to avoid visual bugs
  CLOSEPOCNOTES: (state, data) => {
    state.activeItem = data
  },

  CHANGELOADINGPOCS: (state, payload) => {
    state.loadingPOCs = payload
  },

  AUDITCLEARADMINDATA: (state) => {
    state.adminData = []
  },

  AUDITCLEARMODALADMINDATA: (state) => {
    state.modalAdminData = []
  },

  AUDITPOCUPDATESTATUS: (state, payload) => {
    state.updateStatus = 0
    state.updateStatus = payload
  },

  AUDITCLEARPOCSTATUS: (state) => {
    state.updateStatus = 0
  },

  ADMINUPDATESELECTEDFIELD: (state, data) => {
    state.selectedField = data
  },

  ADMINUPDATEDVALUES: (state, data) => {
    let vals = Object.keys(data)
    vals.map(v => state.updatedValues[v].value = data[v].value)
  },

  ADMINRESETUPDATEDVALUES: (state) => {
    let vals = Object.keys(state.updatedValues)
    vals.map(v => state.updatedValues[v].value = '')
  },

  ADMINPOCSELECTEDFIELD: (state, field) => {
    state.modalField = field
  },

  ADMINEDITSTATUS: (state) => {
    state.editAllToggle = !state.editAllToggle
  },

  AUDITADMINUPDATESTATEVALUES: (state, data) => {
    let compareVal
    if (data.groupType !== 'pocNumber'
      && Object.keys(data.newVals).includes(data.groupType)
      && data.newVals[data.groupType].value) {
      compareVal = data.newVals[data.groupType].value
    }
    else compareVal = data.groupValue
    if (data.groupValue && data.groupValue != '') {
      let existingGroups = state.adminData.map(ad => ad.groupValue)
      if (!existingGroups.includes(compareVal)) {
        state.adminData.push({
          groupValue: compareVal,
          pocs: []
        })
      }
      state.adminData.map(ad => {
        // Remove updated values if the Group Value no longer matches
        ad.pocs = ad.pocs.filter(poc => !data.pocs.includes(poc.blockSerial))
        // Update the state to re-add modified POCs
        data.updated.map(u => {
          if (ad.groupValue === u[data.groupType]) {
            ad.pocs.push(u)
          }
        })
      })
    }
  },
  RESETAUDITOPTIONSVALUES: (state) => {
    state.importSuccess = false
    state.importingData = false
    state.newAuditTableName = ''
  },

  MUTATESTATE: (state, data) => {
    Object.entries(data).forEach(([key, value]) => {
      state[key] = value
    })
  },
}
