<template>
  <div class="font-sans bg-charcoal-100 dark:bg-charcoal-950 text-charcoal dark:text-charcoal-100 antialiased flex flex-col min-h-screen no-scrollbar">
    <component :is="layout" class="flex-1">
      <router-view />
    </component>

    <!-- Global snackbar used by @/behaviors/snackbar -->
    <Snackbar v-model="snack" :position="snackbar.position" :timeout="snackbar.timeout" :tone="snackbar.tone" :heading="snackbar.heading" :actions="snackbar.actions" :dismissable="snackbar.dismissable" :redirect="snackbar.redirect">
      <div v-html="snackbar.message" />
    </Snackbar>

    <!-- Global Confirm Dialog used by calling confirm() from useConfirmDialog composable -->
    <ConfirmDialog />
  </div>
</template>

<script>
/**
 * This non-setup macro'd script has to remain here because Vue 3 does not support
 * dynamic components any other way than this or globally registering components by
 * name.
 */
import Application from '@/layouts/Application.vue'
import Empty from '@/layouts/Empty.vue'
import Snoop from '@/layouts/Snoop.vue'
import Playground from '@/layouts/Experimental/Playground.vue'
import Snackbar from '@/components/Core/Snackbar.vue'

export default {
  components: { Application, Empty, Playground, Snackbar, Snoop }
}
</script>


<script setup>
import { ref, computed, onMounted,  watch } from 'vue'
import './assets/css/nprogress.css'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Mousetrap from 'mousetrap'
import { useMagicKeys } from '@vueuse/core'
import ConfirmDialog from '@/components/Core/ConfirmDialog.vue'

const egg = ref(null)
const store = useStore()
const route = useRoute()

const defaultLayout = ref('Application')
const snackbar = computed(() => store.state.ui.snackbar)

if (import.meta.env.MODE === 'development') {
  const { Command_Ctrl_P } = useMagicKeys()

  // In order to switch to Playground or back to Helper outside of router-view
  watch(Command_Ctrl_P, pressed => {
    if (pressed) {
      defaultLayout.value = defaultLayout.value == 'Application' ? 'Playground' : 'Application'
    }
  })
}

const layout = computed(() => {
  if (route.name === null) {
    return 'Empty'
  }
  return egg.value || route.meta.layout || defaultLayout.value
})

const snack = computed({
  set() {
    store.commit('ui/showSnackbar', {
      ...snackbar,
      show: false
    })
  },
  get() {
    return store.state.ui.snackbar.show
  }
})

const getPermissions = () => store.dispatch('audit/EventServiceGetPermissions')
const auditActiveCheck = () => store.dispatch('audit/EventServiceAuditActiveCheck')

/*
  This is to test the Snackbar to make sure it shows up.
*/
// const alert = () => {
//   store.commit('ui/showSnackbar',{
//     heading: 'Just so you know...',
//     message: 'This is a programmatic snackbar. It is the best.',
//     timeout: 0
//   })
// }

// alert()

onMounted(() => {
  Mousetrap.bind('up up down down left right left right b a enter', () => {
    egg.value = 'Snoop'
  })

  getPermissions()
  auditActiveCheck()
})
</script>