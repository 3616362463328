import store from '../js/store/store.js'

// All axios requests
import axios from 'axios'

const baseURL = import.meta.env.VITE_MODE === 'production' ? '/api/v1' : `https://${import.meta.env.VITE_HELPER_SERVER_NAME}/api/v1`


const apiClient = axios.create({
  baseURL,
  withCredentials: true, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {

  // AUDIT --------------------------

  // GET MY POCS
  getMyPOCs() {
    return apiClient.get('/audit/getMyPOCs')
  },

  // GET MY DIRECT REPORTS
  getMyDirectReports() {
    return apiClient.get('/audit/getMyDirectReports')
  },

  // LOOKUP POC AUDIT METRICS
  getMetrics() {
    return apiClient.get('/audit/metrics')
  },

  // Get persmission
  getPermissions() {
    return apiClient.get('/permissions')
  },

  getAdminFormData() {
    return apiClient.get('/audit/getAdminFormData')
  },

  // Check to see if a user has entered notes
  notesCheck(data) {
    return apiClient.get('/audit/userNotesCheck', {
      params: {
        blockSerial: data.blockSerial,
        ownerSFID: data.ownerSFID
      }
    })
  },

  // Get POC Notes
  getNotes(blockSerial) {
    return apiClient.get('/audit/notes', {
      params: {
        blockSerial
      }
    })
  },

  // Get POC Metric subset data
  getMetricSubset(stage, theater, region, response) {
    return apiClient.get('/audit/metrics/subset/data', {
      params: {
        stage,
        theater,
        region,
        response
      }
    })
  },

  // POST requests
  pocCreateNote(data) {
    return apiClient.post('/audit/addNote', {
      blockSerial: data.blockSerial,
      newNote: data.newNote
    })
  },

  // PUT requests
  submitSEResponse(data) {
    return apiClient.put('/audit/seResponse', {
      blockSerial: data.blockSerial,
      seResponseStatus: data.seResponseStatus
    })
  },

  submitSEMassResponse(data) {
    return apiClient.put('/audit/seMassResponse', {
      blockSerials: data.blockSerials,
      seResponseStatus: data.seResponseStatus,
      newNote: data.newNote
    })
  },

  submitManagerResponse(data) {
    return apiClient.put('/audit/managerResponse', {
      blockSerial: data.blockSerial,
      managerResponseStatus: data.managerResponse
    })
  },

  massApprove(array) {
    return apiClient.put('/audit/managerMassApprove', {
      pocs: array
    })
  },
  getAdminData(searchData) {
    return apiClient.post('/audit/getAdminData', {
      param: searchData.param,
      data: searchData.data
    })
  },
  getModalAdminData(searchData) {
    return apiClient.post('/audit/querySFUserData', {
      data: searchData
    })
  },
  updatePOC(pocData) {
    let payload = pocData
    return apiClient.put('/audit/updatePOC', { payload })
  },
  clearAdminData() {
    store.commit('audit/AUDITCLEARADMINDATA')
  },
  clearModalAdminData() {
    store.commit('audit/AUDITCLEARMODALADMINDATA')
  },
  updatePOCStatus(updateStatus) {
    let payload = updateStatus
    store.commit('audit/AUDITPOCUPDATESTATUS', payload)
  },
  clearPOCStatus() {
    store.commit('audit/AUDITCLEARPOCSTATUS')
  },
  updateAdminStateData(pocData) {
    store.commit('audit/AUDITADMINUPDATESTATEVALUES', pocData)
  },
  resetAdminUpdatedValues() {
    store.commit('audit/ADMINRESETUPDATEDVALUES')
  },
  auditActiveCheck() {
    return apiClient.get('/audit/auditActiveCheck')
  },
  getAuditHistoryData() {
    return apiClient.get('/audit/getHistoryData')
  },
  createNewAuditHistoryTable(data) {
    return apiClient.post('/audit/startNewCycle', {
      tableName: data.tableName,
      startDate: data.startDate,
      stopDate: data.stopDate
    })
  },
  importAuditData() {
    return apiClient.post('/audit/importAuditCycleData')
  },
  activateAuditCycle() {
    return apiClient.put('/audit/activateAuditCycle')
  },
  stopCurrentAuditCycle() {
    return apiClient.put('/audit/stopCurrentAuditCycle')
  }
}
