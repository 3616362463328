import { computed } from 'vue'
import { useElementBounding, breakpointsTailwind, useBreakpoints, useWindowSize } from '@vueuse/core'

export default function useLayoutWidths(el) {
  const { width: browserWidth } = useWindowSize()
  const { width: elementWidth } = useElementBounding(el)
  const breakpoints = useBreakpoints(breakpointsTailwind)

  const breakpoint = computed(() => {
    if (browserWidth.value >= '1920') {
      return '3xl'
    }
    else if(breakpoints.greaterOrEqual('sm').value) {
      return breakpoints.active().value
    }
    else {
      return 'xs'
    }
  })

  return {
    // Variables
    breakpoint,
    elementWidth: computed(() => Math.floor(elementWidth.value) || null ), // Using Math.floor() to get round number
    browserWidth: computed(() => Math.floor(browserWidth.value)), // Using Math.floor() to get round number

    // Functions
    isAboveBreakpoint: (breakpoint) => breakpoints.greater(breakpoint).value,
    isBelowBreakpoint: (breakpoint) => breakpoints.smaller(breakpoint)
  }
}