import { createStore } from 'vuex'

// Audit
import state_Audit from './Audit/stateAudit.js'
import mutations_Audit from './Audit/mutationsAudit.js'
import actions_Audit from './Audit/actionsAudit.js'

import ui from './modules/ui.js'

// Helper
import state from './state.js'

export default createStore({
  //data
  state,

  modules: {
    audit: {
      namespaced: true,
      state: state_Audit,
      mutations: mutations_Audit,
      actions: actions_Audit,
    },
    ui
  }
})


// https://vuex.vuejs.org/guide/modules.html
// const moduleA = {
//   state: { ... },
//   mutations: { ... },
//   actions: { ... },
//   getters: { ... }
// }

// const moduleB = {
//   state: { ... },
//   mutations: { ... },
//   actions: { ... }
// }

// const store = new Vuex.Store({
//   modules: {
//     a: moduleA,
//     b: moduleB
//   }
// })

// store.state.a // -> `moduleA`'s state
// store.state.b // -> `moduleB`'s state
