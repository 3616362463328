import EventService from '@/services/EventService.js'
import { format } from 'date-fns'

export default {

  // Event Services ----------------------
  // Get My POCs
  EventServiceGetMyPOCs: async ({
    commit
  }) => {
    try {
      const temp = await EventService.getMyPOCs()
      commit('MUTATESTATE', {
        myPOCs: temp.data
      })
    }
    catch (err) {
      console.error(err)
    }
  },

  // Get My Direct Reports
  EventServiceGetMyDirectReports: async ({
    commit
  }) => {
    try {
      const reports = await EventService.getMyDirectReports()
      commit('MUTATESTATE', {
        myDirectReports: reports.data
      })
    }
    catch (err) {
      console.error(err)
    }
  },

  // Get Metrics
  EventServiceGetMetrics: async ({
    commit
  }) => {
    try {
      const metrics = await EventService.getMetrics()
      commit('MUTATESTATE', {
        metrics: metrics.data
      })
    }
    catch (err) {
      console.error(err)
    }
  },

  // Get Permissions
  EventServiceGetPermissions: async ({
    commit
  }) => {
    try {
      const response = await EventService.getPermissions()
      commit('MUTATESTATE', {
        permissions: response.data.options
      })
    }
    catch (err) {
      console.error(err)
    }
  },

  EventServiceGetAdminFormData: async ({ commit }) => {
    try {
      const formData = await EventService.getAdminFormData()
      commit('MUTATESTATE', {
        adminFormData: formData.data
      })
    }
    catch (err) {
      console.error(err)
    }
  },

  // Get Notes
  EventServiceGetNotes: ({
    commit
  }, blockSerial) => {
    EventService.getNotes(blockSerial)
      .then(notes => {
        commit('MUTATESTATE', {
          auditPOCNotes: notes.data
        })
      })
      .catch(err => console.error(err))
  },

  EventServiceCreateNote: async ({ commit }, { data }) => {
    try {
      const response = await EventService.pocCreateNote(data)
      if (response.status == 200 && response.data === 'Note Added') {
        await EventService.getNotes(data.blockSerial)
      }
    }
    catch (err) {
      console.error(err)
    }
  },


  EventServiceSEResponse: async ({
    commit
  }, {
    data
  }) => {
    const errors = []

    const result = await EventService.notesCheck(data)

    // If POC has been in the field for more than 90 days, needs an explanation.
    if (data.daysSinceShipped > 90 && !result.data.userNotes && data.auditStage === 'noActionTaken') {
      errors.push('It has been more than 90 days since this POC was shipped. The typical length of a physical POC is 30 days. Please provide an explanation in your note.')
    }

    // If you mark a POC Incorrect, it MUST have an attached note.
    if (data.seResponseStatus === 'Incorrect' && !result.data.userNotes && data.auditStage === 'noActionTaken') {
      errors.push('You have marked this POC as Incorrect. Update entry in Salesforce to reflect accurate information, leave a note as to the needed changes.')
    }

    // If any errors, throw and leave Action early.
    if (errors.length > 0) {
      throw {
        title: 'You must leave a note',
        errors
      }
    }

    await EventService.submitSEResponse(data)

  },

  EventServiceSEMassResponse: async ({ commit }, {
    data
  }) => {
    // const errors = []

    await EventService.submitSEMassResponse(data)

  },

  EventServiceManagerResponse: async ({
    commit
  }, {
    data
  }) => {
    try {
      await EventService.submitManagerResponse(data)
    }
    catch (err) {
      console.error(err)
    }
  },

  EventServiceMassApprove: ({
    commit
  }, {
    array
  }) => {
    return EventService.massApprove(array)
      .catch(console.error)
  },

  EventServiceLoadMetricSubset: ({
    commit
  }, {
    data
  }) => {
    EventService.getMetricSubset(data.stage, data.theater, data.region, data.response)
      .then(metrics => {
        commit('MUTATESTATE', {
          dataPOCView: metrics.data
        })
      }
      ).catch(err => console.error(err))
  },

  EventServiceGetAdminData: ({
    commit
  }, {
    searchData
  }) => {
    commit('MUTATESTATE', {
      showAudits: false,
      searchIsLoading: true
    })
    EventService.getAdminData(searchData)
      .then(pocs => {
        commit('MUTATESTATE', {
          showAudits: true,
          searchIsLoading: false,
          adminData: pocs.data
        })
      }).catch(err => console.error(err))
  },

  EventServiceGetModalAdminData: async ({
    commit
  }, {
    searchData
  }) => {
    commit('MUTATESTATE', {
      searchModalIsLoading: true
    })

    try {
      let pocs = await EventService.getModalAdminData(searchData)

      commit('MUTATESTATE', {
        modalAdminData: pocs.data
      })
    }
    catch (err) {
      console.error(err)
    }
    finally {
      commit('MUTATESTATE', {
        searchModalIsLoading: false
      })
    }
  },

  EventServiceUpdatePOC: async ({
    commit
  }, {
    pocData
  }) => {
    try {
      await EventService.updatePOC(pocData)
    }
    catch (err) {
      console.error(err)
    }
  },

  EventServiceClearPOCStatus: ({
    commit
  }) => {
    EventService.clearPOCStatus()
  },

  EventServiceClearAdminData: ({
    commit
  }) => {
    EventService.clearAdminData()
  },

  EventServiceClearModalAdminData: ({
    commit
  }) => {
    EventService.clearModalAdminData()
  },

  EventServiceUpdateAdminData: ({
    commit
  }) => {
    EventService.clearModalAdminData()
  },

  EventServiceResetAdminUpdatedValues: ({
    commit
  }) => {
    EventService.resetAdminUpdatedValues()
  },

  EventServiceImportAuditData: ({
    commit
  }) => {
    EventService.importAuditData()
      .then(() => {
        commit('MUTATESTATE', {
          importSuccess: true
        })
      })
      .catch(err => {
        commit('MUTATESTATE', {
          importSuccess: false
        })
        console.error(err)
      })
  },

  EventServiceGetAuditHistoryData: ({
    commit
  }) => {
    EventService.getAuditHistoryData()
      .then(response => {
        commit('MUTATESTATE', {
          auditHistoryData: response.data
        })
      })
      .catch(err => console.error(err))
  },

  EventServiceCreatAuditHistoryTable: async ({
    commit
  }, {
    tableData
  }) => {
    try {
      await EventService.createNewAuditHistoryTable(tableData)
    }
    catch (err) {
      console.error(err)
    }
  },

  EventServiceActivateAuditCycle: ({ commit }) => {
    EventService.activateAuditCycle()
      .then(() => {
        EventService.getAuditHistoryData()
          .then(response => {
            commit('MUTATESTATE', {
              auditHistoryData: response.data
            })
          })
      })
      .catch(err => console.error(err))
  },

  EventServiceStopCurrentAuditCycle: ({
    commit
  }) => {
    EventService.stopCurrentAuditCycle()
      .then(() => {
        EventService.getAuditHistoryData()
          .then(response => {
            commit('MUTATESTATE', {
              auditHistoryData: response.data
            })
          })
      })
      .catch(err => console.error(err))
  },

  EventServiceAuditActiveCheck: ({ commit }) => {
    EventService.auditActiveCheck()
      .then((response) => {
        commit('MUTATESTATE', {
          audit_Active: response.data.active,
          audit_StartDate: response.data.startDate === 'N/A' ? response.data.startDate : format(new Date(response.data.startDate), 'yyyy-MM-dd HH:mm z'),
          audit_StopDate: response.data.stopDate === 'N/A' ? response.data.stopDate : format(new Date(response.data.stopDate), 'yyyy-MM-dd HH:mm z')
        })
      })
      .catch(err => console.error(err))
  },

  // END Event Services ----------------------


  // Toggle metric tabs
  togglingShowMetricPOCs: ({ commit }, { payload }) => {
    commit('TOGGLESHOWMETRICPOCS', payload)
  },

  // Update POC notes
  pocNotesUpdate: ({ commit }, data) => {
    commit('POCNOTESUPDATE', data)
  },
  pocEditNotesUpdate: ({ commit }, data) => {
    commit('POCEDITNOTESUPDATE', data)
  }

}
