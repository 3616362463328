export default {
  namespaced: true,

  state: {
    snackbar: {
      show: false,
      tone: 'info',
      heading: '',
      message: '',
      actions: [],
      timeout: 3000,
      dismissable: true,
      redirect: {
        show: false,
        href: '',
        text: ''
      }
    }
  },

  mutations: {
    showSnackbar(state, payload) {
      /*
        Handles resetting state when snackbar closes
      */
      if (payload.show === false) {
        Object.assign(state.snackbar, {
          show: false,
          tone: 'info',
          heading: '',
          message: '',
          actions: [],
          timeout: 3000,
          dismissable: true,
          redirect: {
            show: false,
            href: '',
            text: ''
          }
        })
        return
      }

      if (!payload.message) {
        console.error('You MUST provide a message when using the Snackbar.')
        return
      }

      if (payload.timeout && payload.timeout < 0) {
        console.error('Negative Snackbar timeouts are silly.')
      }

      if (payload.tone) {
        state.snackbar.tone = payload.tone
      }

      if (payload.dismissable !== undefined) {
        state.snackbar.dismissable = Boolean(payload.dismissable)
      }

      if (payload.timeout !== undefined) {
        state.snackbar.timeout = payload.timeout
      } else {
        state.snackbar.timeout = 3000
      }

      if (payload.actions !== undefined && payload.actions.length > 0) {
        state.snackbar.actions = payload.actions
      } else {
        state.snackbar.actions = []
      }

      if (payload.redirect) {
        state.snackbar.redirect = payload.redirect
      }

      state.snackbar.show = payload.show === undefined ? true : payload.show
      state.snackbar.message = payload.message
      state.snackbar.heading = payload.heading
    },

    dismiss(state) {
      state.snackbar.show = false
    }
  }
}
