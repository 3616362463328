import defaultTheme from 'tailwindcss/defaultTheme'

export default {
  content: [
    './index.html', './src/**/*.{html,vue,js,jsx}'
  ],
  darkMode: 'class',
  theme: {
    extend: {
      colors: {
        black: '#000000',
        transparent: 'transparent',
        'semi-black': '#0d0f11',
        'semi-white': '#f6f7f8',
        'semi-75': 'rgba(0, 0, 0, 0.75)',
        'deep-purple': '#100035',
        'semi-gray': '#FAFAFA',
        white: '#ffffff',
        brand: {
          'classic-blue': '#024da1',
          green: '#afd135',
          'dark-gray': '#4c4c4e',
          yellow: '#edad1f',
          orange: '#ff731c',
          'light-blue': '#3abfef',
          purple: '#8347ee',
          gray: '#76787a'
        },
        'sfdc-blue': '#009EDB',
        'primary-link': '#6D40E6',
        'dark-primary-link': '#ac98fd',
        'iris': {
          100: '#e0e1ff',
          200: '#c6befe',
          300: '#ac98fd',
          400: '#9278fb',
          500: '#7855fa',
          600: '#6d40e6',
          700: '#612bd2',
          800: '#5615be',
          900: '#4b00aa'
        },
        'charcoal': {
          100: '#f0f0f0',
          200: '#d7d7d7',
          300: '#bfbfbf',
          400: '#a6a6a6',
          500: '#8e8e8e',
          600: '#757575',
          700: '#5d5d5d',
          800: '#444444',
          850: '#333333',
          900: '#2c2c2c',
          950: '#1f1f1f',
          DEFAULT: '#131313'
        },
        'aqua': {
          100: '#b2f8ff',
          200: '#8df1fa',
          300: '#69ebf4',
          400: '#44e4ef',
          500: '#1fdde9',
          600: '#17cadb',
          700: '#10b8cd',
          800: '#08a5be',
          900: '#0092b0'
        },
        'mantis': {
          100: '#dbfab1',
          200: '#c9f38d',
          300: '#b6ec6a',
          400: '#a4e447',
          500: '#92dd23',
          600: '#7ed01a',
          700: '#6ac312',
          800: '#55b709',
          900: '#41aa00'
        },
        'peach': {
          100: '#ffe2d4',
          200: '#ffcebd',
          300: '#ffbaa6,',
          400: '#ffa58f',
          500: '#ff9178',
          600: '#f57a62',
          700: '#eb644b',
          800: '#e14e35',
          900: '#d7371e'
        }

      },
      fontFamily: {
        sans: ['Arial',...defaultTheme.fontFamily.sans],
        attention: ['Montserrat','Arial', ...defaultTheme.fontFamily.sans]
      },
      inset: {
        '4': '1rem',
        'full': '100%'
      },
      cursor: {
        'none': 'none'
      },
      screens: {
        '3xl': '1920px'
      },
      margin: {
        '18': '4.5rem'
      }
    }
  },
  plugins: [
    import('@tailwindcss/forms'),
    import('@tailwindcss/aspect-ratio'),
    import('@tailwindcss/typography'),
  ]
}