<template>
  <div :class="[
    'flex flex-col min-w-0',
    computedWidth
  ]">
    <slot />
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import useLayoutWidths from '@/composables/useLayoutWidths.js'

const widthsIntoClasses = {
  '1/5': 'w-1/5',
  '1/4': 'w-1/4',
  '1/3': 'w-1/3',
  '2/5': 'w-2/5',
  '1/2': 'w-1/2',
  '3/5': 'w-3/5',
  '2/3': 'w-2/3',
  '3/4': 'w-3/4',
  '4/5': 'w-4/5'
}

const props = defineProps({
  width: {
    type: String,
    validator: (val) => ['1/5', '1/4', '1/3', '2/5', '1/2', '3/5', '2/3', '3/4', '4/5'].includes(val)
  }
})

const collapseBelow = inject('collapseBelow')

const { isBelowBreakpoint } = useLayoutWidths()

const computedWidth = computed(() => {
  if (props.width) {
    return isBelowBreakpoint(collapseBelow).value ? 'w-full' : widthsIntoClasses[props.width]
  }

  return 'flex-1'
})
</script>
