<!--

# Snackbar

Snackbars are little notifications that show up at the top or
bottom of the screen when something happens.

Typically, they'll have some Alert-esque styling with text and
an optional call to action.

-->
<template>
  <transition
      enter-active-class="transform ease-out duration-300 transition"
      leave-active-class="transition ease-in duration-100"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
    <div v-show="modelValue" class="fixed z-50 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <div class="max-w-sm w-full shadow-lg rounded-lg pointer-events-auto" :class="classes.background[this.tone]">
        <div class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <Icon :icon="icons[this.tone]" class="h-6 w-6" :class="classes.icon[this.tone]" />
              </div>
              <div class="ml-3 w-0 flex-1">
                <p v-if="heading" class="mb-2 text-sm leading-5 font-semibold" :class="classes.heading[this.tone]">
                  {{ heading }}
                </p>
                <p class="text-sm leading-4" :class="classes.text[this.tone]">
                  <slot></slot>
                </p>
                <div class="mt-4 flex space-x-4">
                  <Button v-for="(action, i) in actions" :key="i" class="flex-1" :color="action.button.color" @click="action.click">
                    <Icon v-if="action.button.icon" :class="action.button.iconStyle" :icon="`heroicons:${action.button.icon}`"/>
                    {{ action.label }}
                  </Button>
                </div>
              </div>
              <div class="ml-4 flex-shrink-0 flex" v-if="dismissable" @click="() => { hide(); $emit('dismiss') }">
                <span class="inline-flex transition ease-in-out duration-150" :class="classes.text[this.tone]">
                  <Icon icon="heroicons:x-mark" v-if="dismissable" @click="() => { hide(); $emit('dismiss') }" class="w-5 h-5" />
                </span>
              </div>
            </div>
            <a v-if="redirect.show" class="flex justify-center p-2 cursor-pointer text-iris-700 hover:text-iris-900 text-sm" :href="redirect.href" :class="classes.redirect[this.tone]" target="_blank">{{ redirect.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Icon } from '@iconify/vue'
import Button from '@/components/Core/Button.vue'

export default {
  name: 'Snackbar',

  components: { Button, Icon },

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },

    heading: {
      type: String,
      required: false
    },

    actions: {
      type: Array,
    },

    tone: {
      type: String,
      validator: (val) => ['info', 'failure', 'success'].includes(val),
      default: 'info'
    },

    timeout: {
      type: Number,
      default: 0
    },

    dismissable: {
      type: Boolean,
      default: true
    },

    redirect: {
      type: Object,
      required: false,
      default: () => {
        return {
          show: false,
          href: '',
          text: ''
        }
      }
    }
  },
  emits: ['dismiss', 'update:modelValue'],

  data() {
    return {
      icons: {
        info: 'heroicons:information-circle',
        failure: 'heroicons:exclamation-triangle',
        success: 'heroicons:check-circle',
      },
      classes: {
        background: {
          info: 'bg-iris-100',
          failure: 'bg-red-200',
          success: 'bg-green-100',
        },
        icon: {
          info: 'text-primary-link',
          failure: 'text-red-900',
          success: 'text-green-800'
        },
        heading: {
          info: 'text-iris-900',
          failure: 'text-red-900',
          success: 'text-green-900'
        },
        text: {
          info: 'text-charcoal-800',
          failure: 'text-red-900',
          success: 'text-charcoal-800'
        },
        redirect: {
          info: 'bg-iris-200 hover:bg-iris-300',
          failure: 'bg-red-300 hover:bg-red-400',
          success: 'bg-green-200 hover:bg-green-300',
        }
      }
    }
  },

  methods: {
    show() {
      this.$emit('update:modelValue', true)
    },

    hide() {
      this.$emit('update:modelValue', false)

      if (this.timer) {
        window.clearTimeout(this.timer)
      }
    },
  },

  watch: {
    timeout() {
      if (this.timer) {
        window.clearTimeout(this.timer)
      }

      if (this.modelValue && this.timeout > 0) {
        this.timer = window.setTimeout(this.hide, this.timeout)
      }
    }
  },

  updated() {
    this.$nextTick(() => {
      if (this.modelValue && this.timeout > 0) {
        if (this.timer) {
          window.clearTimeout(this.timer)
        }

        this.timer = window.setTimeout(this.hide, this.timeout)
      }
    })
  }
}
</script>
