<template></template>

<script setup>
import { onMounted, onBeforeUnmount, inject, useSlots, Text } from 'vue'

const props = defineProps({
  value: [String, Object, Number, Boolean]
})

const slots = useSlots()

const api = inject('dropdown')

onMounted(() => {
  let label = props.value

  const children = slots.default ? slots.default() : []

  if (children.length === 1 && children[0].type === Text) {
    label = children[0].children
  }

  if (api.register) {
    api.register({ label, value: props.value })
  }
})

onBeforeUnmount(() => {
  if (api.unregister) {
    api.unregister(props.value)
  }
})
</script>