<template>
  <div :class="[
    'flex flex-col min-w-0',
    // Makes Columns w-full
    full ? 'w-full' : null,
    // Gap between columns
    {
      'gap-1': gap === 'xxsmall',
      'gap-2': gap === 'xsmall',
      'gap-3': gap === 'small',
      'gap-4': gap === 'medium',
      'gap-6': gap === 'large',
      'gap-8': gap === 'xlarge',
      'gap-10': gap === 'xxlarge',
      'gap-5': gap === 'gutter',
    },
    // When to collapse into single column
    `${collapseBelow}:flex-row`
  ]">
    <slot />
  </div>
</template>

<script setup>
import { provide } from 'vue'

const props = defineProps({
  full: {
    type: Boolean
  },
  gap: {
    type: String,
    validator: (val) => ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'gutter'].includes(val)
  },
  collapseBelow: {
    type: String,
    validator: (val) => ['sm', 'md', 'lg', 'xl', '2xl', '3xl'].includes(val),
    default: 'sm'
  },
  fullWidth: {
    type: Boolean
  }
})

provide('collapseBelow', props.collapseBelow)
</script>