<template>
  <slot name="activator" v-if="$slots.activator" :open="open" />

  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 z-40 bg-charcoal-500/75 transition-opacity dark:bg-charcoal-800/60 backdrop-blur-lg" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            @afterLeave="$emit('closed')"
          >
            <DialogPanel
              class="flex flex-col relative transform rounded-lg bg-white dark:bg-charcoal-950 px-8 pt-8 pb-8 text-left shadow-xl transition-all "
              :class="[sizes[full ? 'full' : tall ? 'tall' : 'default']]"
            >
              <div v-if="heading" class="mb-3 flex items-center justify-between">
                <h2 v-if="heading" class="text-lg font-semibold text-charcoal-800 dark:text-charcoal-200">{{ heading }}</h2>
                <Icon name="heroicons:x-mark" @click="close" class="w-5 h-5 shrink-0 text-charcoal-800 dark:text-charcoal-200" />
              </div>
              <div class="flex-1 flex flex-col text-left">
                <slot :close="close" />
              </div>
              <div v-if="$slots.toolbar" class="mt-8">
                <slot name="toolbar" :close="close" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { Icon } from '@iconify/vue'

const props = defineProps({
  modelValue: Boolean,
  heading: String,
  full: Boolean,
  tall: Boolean,
})

const sizes = {
  full: 'sm:w-[90vw] sm:h-[90vh]',
  tall: 'sm:min-h-[60vh]',
  default: ''
}

const emit = defineEmits(['open', 'close', 'closed', 'update:modelValue'])

const isOpen = ref(true)

watchEffect(() => {
  isOpen.value = props.modelValue
})

function close() {
  emit('close')
  isOpen.value = false
  emit('update:modelValue', false)
}

function open() {
  emit('open')
  isOpen.value = true
  emit('update:modelValue', true)
}
</script>
