<template>
   <div class="fixed inset-0">
      <video autoplay muted loop class="absolute inset-0 w-full">
         <source src="https://media.giphy.com/media/Lo0TixlRiatXIvVWWN/giphy.mp4" type="video/mp4" />
      </video>
   </div>
</template>

<script>
export default {
  name: 'Snoop'
}
</script>
