<template>
  <div v-if="visible" class="flex items-center bg-charcoal-900 py-2 px-3 space-x-4 sm:space-x-8 text-white">
    <div class="flex items-center space-x-2" :content="`${branch} / ${commit}`" v-tippy>
      <Icon icon="heroicons:code-bracket" class="flex-shrink-0 w-5 h-5" />
      <p class="hidden sm:block font-mono text-xs">
        {{ branch }} / {{ commit }}
      </p>
    </div>
    <div class="flex items-center space-x-2" :content="`${salesforce}`" v-tippy>
      <Icon icon="heroicons:globe-alt" class="flex-shrink-0 w-5 h-5" />
      <p class="hidden sm:block font-mono text-xs">
        {{ salesforce }}
      </p>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'

export default {
  name: 'BuildInformation',

  components: { Icon },

  data() {
    return {
      branch: import.meta.env.VITE_CI_BRANCH_NAME,
      commit: import.meta.env.VITE_CI_COMMIT_SHORT_SHA,
      salesforce: this.$salesforce
    }
  },

  computed: {
    visible() {
      return import.meta.env.VITE_SHOW_BUILD == 'true'
    }
  }
}
</script>
