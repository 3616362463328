<template>
  <div class="flex items-center w-full">
    <div class="ml-3 w-full">
      <!-- Search -->
      <div class="relative">
        <!-- Icon -->
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon icon="heroicons:magnifying-glass" class="flex-shrink-0 h-4 w-4 text-charcoal-900 dark:text-charcoal-400"/>
        </div>

        <!-- Loader -->
        <div v-if="form.loading" class="absolute inset-y-0 right-0 pr-4 flex items-center">
          <Loader :size="20" />
        </div>

        <!-- Search Input -->
        <input ref="search" @focus="form.hasFocus = true" v-model="form.search"
          @keydown.down="handleDownKey" @keydown.up="handleUpKey" @keydown.enter="handleEnterKey" @keydown.esc="handleEscapeKey"
          class="block w-full pl-9 py-2 pr-10 bg-iris-200 rounded-md text-sm placeholder-charcoal-900 text-charcoal-900 tracking-wide focus:outline-none dark:bg-iris-600 dark:placeholder-charcoal-200 dark:text-charcoal-100"
          placeholder='Search everything (Press "/" to focus)' />

        <!-- Results Panel -->
        <div v-show="(hits.length > 0 || form.search.length > 0) && form.hasFocus" class="absolute w-full mt-2 z-50 shadow-lg bg-white rounded py-2 px-2 dark:bg-charcoal-900 dark:border dark:border-charcoal-700">
          <div v-show="results.length > 0">
            <div class="flex items-center justify-between py-2 px-3 mb-2">
              <div class="flex items-center">
                <Loader v-if="form.loading" :size="16" color="#bbb" class="mr-2" />
                <Icon v-else icon="heroicons:check" class="flex-shrink-0 w-4 h-4 mr-2 text-charcoal-400 dark:text-charcoal-300" />
                <p class="text-sm tet-charcoal-600 dark:text-charcoal-300">Found {{ results.length }} results<span class="hidden sm:inline"> matching "{{ form.search }}"</span></p>
              </div>
              <Button color="transparent" @click="reset"><Icon icon="heroicons:x-circle" class="flex-shrink-0 w-5 h-5 text-charcoal-400 hover:text-charcoal-600 dark:text-charcoal-400 dark:hover:text-charcoal-200" /></Button>
            </div>
            <div class="max-h-60 overflow-hidden overflow-y-scroll transition-all duration-150 ease-out focus:outline-none" ref="hits">
              <div v-for="(hit, i) in results" :key="i">
                <router-link :to="routeFromHit(hit)" @click="reset" class="flex items-center justify-between py-2 px-3 rounded-lg hover:bg-charcoal-100 text-charcoal-800 dark:text-charcoal-200 dark:hover:bg-charcoal-950 space-x-8" :class="form.selectedIndex == i ? 'bg-charcoal-100 dark:bg-charcoal-950' : ''">
                  <div class="flex items-center">
                    <Icon :icon="icons[hit.ref.type]" class="flex-shrink-0 w-4 h-4 mr-3 text-charcoal-400 dark:text-charcoal-400" />
                      <span class="text-sm text-charcoal-800 dark:text-charcoal-200 w-auto sm:w-auto truncate">{{ hit.title }}</span>
                  </div>
                  <div class="hidden sm:block">
                    <Badge v-if="hit.ref.type === 'account'" tone="info" class="uppercase" :label="hit.ref.type">{{ hit.ref.object.type }}</Badge>
                    <Badge v-else tone="info" class="uppercase">{{ hit.ref.type}}</Badge>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div v-show="results.length === 0" class="py-2 px-3 flex items-center justify-between">
            <div class="text-sm text-charcoal-600 dark:text-charcoal-400 flex items-center">
              <Loader v-if="form.loading" :size="16" color="#bbb" class="mr-2" />
              <Icon v-else icon="heroicons:magnifying-glass" class="flex-shrink-0 w-4 h-4 mr-2 text-charcoal-400" />
              <p class="text-sm text-charcoal-600 dark:text-charcoal-300">
                <span v-if="form.loading">Searching...</span>
                <span v-else>No results<span class="hidden sm:inline"> matching "{{ form.search }}"</span> <span v-if="form.search.length == 1">. Search term must be longer than one character.</span></span>
              </p>
            </div>
            <Button color="transparent" @click="reset"><Icon icon="heroicons:x-circle" class="flex-shrink-0 w-5 h-5 text-charcoal-400 hover:text-charcoal-600 dark:text-charcoal-400 dark:hover:text-charcoal-200" /></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Mousetrap from 'mousetrap'
import qs from 'qs'
import { Icon } from '@iconify/vue'
import Badge from '@/components/Core/Badge.vue'
import Loader from '@/components/Core/Loader.vue'
import { v2 } from '@/services/api.js'
import { search } from '@/js/utils.js'
import Button from '@/components/Core/Button.vue'


export default {
  name: 'Omnisearch',

  components: { Badge, Icon, Loader, Button },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    accounts: {
      type: Array
    }
  },

  data() {
    return {
      form: {
        search: '',
        hasFocus: false,
        loading: false,
        selectedIndex: 0
      },
      icons: {
        account: 'heroicons:building-office',
        opportunity: 'heroicons:sparkles',
        contact: 'heroicons:user',
      },
      hits: [],
    }
  },

  watch: {
    'form.search': {
      handler: function () {
        if (this.form.search.length <= 1) {
          return
        }

        this.form.loading = true
        this.search()
      }
    },

    'form.selectedIndex'() {
      this.$refs.hits.children[this.form.selectedIndex].scrollIntoView(false)
    }
  },

  computed: {
    results() {
      if (this.form.search.length === 0) {
        return []
      }
      //? ask about title!!!!!!
      return search(this.form.search, this.hits, ['title'], 25)
    }
  },

  methods: {
    search: _.debounce(function () {
      v2.get('/search', {
        params: {
          filter: {
            name: this.form.search
          }
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      })
        .then(async response => {
          this.hits = response.data
        })
        .finally(() => {
          this.form.loading = false
        })
    }, 500),

    reset() {
      this.form.search = ''
      this.hits = []
      this.$refs.search.blur()
    },

    routeFromHit(hit) {
      let routeName

      if (hit.ref.type === 'account') {
        routeName = 'account.opportunities'
      } else if (hit.ref.type === 'opportunity') {
        routeName = 'opportunity.details'
      } else if (hit.ref.type === 'contact') {
        routeName = 'contacts.show'
      }

      return {
        name: routeName,
        params: {
          id: hit.ref.id
        }
      }
    },

    next() {
      if (++this.form.selectedIndex >= this.results.length) {
        this.form.selectedIndex = 0
      }
    },

    prev() {
      if (--this.form.selectedIndex < 0) {
        this.form.selectedIndex = this.results.length - 1
      }
    },


    /**
     * Everything that follows deals with the details of keyboard navigation
     * or focus state. These are event listeners that call methods that perform
     * state manipulation. Don't do any logic here.
     */

    focusSearch(e) {
      e.stopPropagation()
      e.preventDefault()
      this.$refs.search.focus()
    },

    blurOmnisearch(e) {
      if (!this.$el.contains(e.target)) {
        this.form.hasFocus = false
      }
    },

    handleDownKey(e) {
      if (this.form.hasFocus) {
        e.preventDefault()
        this.next()
      }
    },

    handleUpKey(e) {
      if (this.form.hasFocus) {
        e.preventDefault()
        this.prev()
      }
    },

    handleEnterKey(e) {
      if (this.form.hasFocus) {
        e.preventDefault()
        this.$router.push(this.routeFromHit(this.results[this.form.selectedIndex]))
        this.reset()
      }
    },

    handleEscapeKey(e) {
      if (this.form.hasFocus) {
        e.preventDefault()
        this.reset()
      }
    }
  },

  mounted() {
    Mousetrap.bind('/', this.focusSearch)
    Mousetrap.bind('down', this.handleDownKey)
    Mousetrap.bind('up', this.handleUpKey)
    Mousetrap.bind('enter', this.handleEnterKey)
    Mousetrap.bind('escape', this.handleEscapeKey)
    document.addEventListener('click', this.blurOmnisearch)
  },

  unmounted() {
    Mousetrap.unbind('/')
    Mousetrap.unbind('down')
    Mousetrap.unbind('up')
    Mousetrap.unbind('enter')
    Mousetrap.unbind('escape')
    document.removeEventListener('click', this.blurOmnisearch)
  }
}
</script>
