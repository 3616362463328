<template>
  <!-- wrapper classes -->
  <span :class="wrapperClasses">
    <!-- label side -->
    <div v-if="label" class="capitalize" :class="labelClasses">{{ label }}</div>
    <!-- content side -->
    <div :class="contentClasses">
      <slot></slot>
    </div>
  </span>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    tone: {
      type: String,
      validator: (val) => ['neutral', 'info', 'caution', 'warning', 'caution-nav', 'critical', 'positive', 'dark'].includes(val),
      default: 'neutral'
    },

    size: {
      type: String,
      validator: (val) => ['small','base', 'lg'].includes(val),
      default: 'base'
    },

    label: {
      type: String
    },

    outline: {
      type: Boolean,
    }
  },

  computed: {
    classes() {
      return {
        sizes: {
          small: 'px-2',
          base: 'px-3 py-1',
          lg: 'px-3 py-2'
        },
        label: {
          neutral: 'bg-charcoal-800 text-white',
          info: 'bg-primary-link text-white',
          caution: 'bg-amber-700 text-white',
          warning: 'bg-orange-700 text-white',
          critical: 'bg-peach-900/20 text-white',
          positive: 'bg-green-700 text-white',
          dark: 'bg-charcoal-500 text-white',
        },
        content: {
          neutral: this.outline  ? 'text-charcoal-900' : 'bg-charcoal-400/20 dark:text-charcoal-100 text-charcoal-900',
          info: this.outline  ? 'text-primary-link' : 'bg-iris-400/20 text-primary-link dark:text-iris-200',
          caution: this.outline  ? 'text-amber-700 dark:text-amber-400' : 'bg-amber-400/30 dark:bg-amber-400/20 text-amber-800 dark:text-amber-300',
          'caution-nav': 'bg-yellow-300/80 text-yellow-900',
          warning: this.outline ? 'text-orange-700' :'bg-orange-400/40 text-orange-800 dark:text-orange-300',
          critical: this.outline  ? 'bg-transparent text-peach-900 dark:text-red-300' : 'bg-peach-500/20 dark:bg-peach-900/20 text-red-700 dark:text-red-300',
          positive: this.outline  ? 'text-mantis-900' : 'bg-mantis-800/20 text-lime-800 dark:text-mantis-300',
          dark: this.outline  ? 'text-charcoal-800' : 'bg-charcoal-500/10 text-white'
        }
      }
    },

    wrapperClasses() {
      let classes = ['inline-flex shrink-0 items-center rounded-full text-xs font-medium border leading-4 overflow-hidden']
      if (this.outline) {
        classes.push(this.borderClasses)
      }
      else {
        classes.push('border-transparent')
      }
      return classes
    },

    labelClasses() {
      let classes = []
      if (this.tone) {
        classes.push(this.classes.label[this.tone])
      }
      if (this.size) {
        classes.push(this.classes.sizes[this.size])
      }
      return classes
    },

    contentClasses() {
      let classes = ['flex whitespace-nowrap items-center']
      if(this.label) {
        classes.push('rounded-r-full')
      } else {
        classes.push('rounded-full')
      }
      if (this.tone) {
        classes.push(this.classes.content[this.tone])
      }
      if (this.size) {
        classes.push(this.classes.sizes[this.size])
      }
      return classes
    },

    borderClasses() {
      return {
        neutral: 'border-charcoal-500',
        info: 'border-iris-500',
        caution: 'border-amber-500',
        'caution-nav': 'border-yellow-500',
        warning: 'border-orange-500',
        critical: 'border-red-600 dark:border-red-500',
        positive: 'border-green-500',
        dark: 'border-charcoal-500',
      }[this.tone]
    }
  },

  mounted() {
    if (this.$slots.default === undefined) {
      console.error('Badge component requires slot content.')
    }
  }
}
</script>
