export default {
  // Audit
  audit_Active: false,
  audit_StartDate: 'June 30, 2020',
  audit_StopDate: 'July 30, 2020',

  // AUDIT DATA
  myPOCs: [],
  myDirectReports: [],

  // NOTES
  modalPOCNotes: false,
  auditPOCNotes: '',
  newAuditPOCNotes: '',
  notesModalShown: false,
  editedPOCNotes: '',
  activeItem: -1,
  pocErrorMessage: {
    show: false,
    type: '',
    index: -1
  },
  errorMsgIncorrect: 'You have chosen to mark this POC as \'Incorrect,\' but you have not left a note yet explaining why. Please leave a note before submitting your response for this POC.',
  errorMsgDays: 'It has been more than 90 days since this POC was shipped. The typical length of a physical POC is 30 days. You must leave a note with an explanation as to why this POC is still outstanding before you can submit a response.',
  errorMsgBoth: 'It has been more than 90 days since this POC was shipped. You also chose \'Incorrect\' as your response. You must leave a note with an explanation for both of these issues for the auditors to review.',


  // GET Metrics
  // Default Permissions object
  permissions: {
    auditApprover: false,
    auditInitiator: false,
    auditor: '',
    isManager: false,
    ownsPOCs: false,
    region: [],
    theater: []
  },
  metrics: '',
  selected: {
    metric: '',
    regions: ''
  },
  showMetricPOCs: false,
  loadingPOCs: false,
  dataPOCView: [],
  searchIsLoading:false,
  searchModalIsLoading:false,
  showAudits:false,


  // DIRECT REPORTS
  directReports: {
    activeFilters: ['Not Started', 'Needs Manager Approval', 'Needs Review', 'Completed',],
    filters: {
      noActionTaken: {
        name: 'Not Started',
        icon: new URL('./../../../assets/icons/auditNotStarted.svg', import.meta.url).href,
      },
      needsApproval: {
        name: 'Needs Manager Approval',
        icon: new URL('./../../../assets/icons/auditManagerApproval.svg', import.meta.url).href,
      },
      needsReview: {
        name: 'Needs Review',
        icon: new URL('./../../../assets/icons/auditReview.svg', import.meta.url).href,
      },
      completedAudit: {
        name: 'Completed',
        icon: new URL('./../../../assets/icons/auditCompleted.svg', import.meta.url).href,
      }
    },
  },
  // Admin View
  adminData: [],
  adminFormData: {},
  modalAdminData: [],
  updateStatus: 0,
  searchableFields: {
    owner: {
      label: 'Owner',
      type: 'searchParam',
      value: 'owner'
    },
    manager: {
      label: 'Manager',
      type: 'searchParam',
      value: 'manager'
    },
    accountName: {
      label: 'Account Name',
      type: 'searchParam',
      value: 'accountName'
    },
    pocNumber: {
      label: 'POC #',
      type: 'searchParam',
      value: 'pocNumber'
    },
    userTheater: {
      label: 'Theater',
      type: 'searchParam',
      value: 'userTheater'
    },
    installCountry: {
      label: 'Install Country',
      type: 'searchParam',
      value: 'installCountry'
    }
  },
  selectedField: 'owner',
  updatedValues: {
    owner: {
      label: 'Owner',
      value: ''
    },
    ownerEmail: {
      label: 'Owner Email',
      value: ''
    },
    ownerSFID: {
      label: 'Owner SFID',
      value: ''
    },
    manager: {
      label: 'Manager',
      value: ''
    },
    userRegion: {
      label: 'Region',
      value: ''
    },
    userTheater: {
      label: 'Theater',
      value: ''
    },
    auditStage: {
      label: 'Stage',
      value: ''
    }
  },
  modalField: 'owner',
  editAllToggle: false,
  auditHistoryData: [],
  importingData: false,
  importSuccess: false,
  newAuditTableName: ''
}
