import store from '@/js/store/store.js'

export function show(snack) {
  store.commit('ui/showSnackbar', snack)
}

export function dismiss() {
  store.commit('ui/dismiss')
}

export default {
  show,
  dismiss
}
